.bs-progress {
    display: flex;
    height: 0.5rem;
    overflow: hidden;
    font-size: 0.35rem;
    background-color: var(--surface-d);
    border-radius: var(--border-radius);
}
.bs-progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #1E88E5;
    transition: width 0.6s ease;
}
.bs-progress-bar-striped {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 0.5rem 0.5rem;
}
.bs-progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 0.5rem;
    }
}
