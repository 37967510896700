.ngx-colors-overlay{
    z-index: 9999999 !important;
}
#ngx-colors-overlay .opened {
    background: var(--primary-bg-400) !important;
}

#ngx-colors-overlay .opened .circle {
    border-radius: var(--border-radius);
    -webkit-transition: none !important;
    transition: none !important;
}

#ngx-colors-overlay .opened .nav-wrapper {
    margin: 15px;
}

#ngx-colors-overlay .opened .nav-wrapper button {
    color: var(--primary-bg-200);
    cursor: pointer;
}

#ngx-colors-overlay .opened .nav-wrapper .round-button {
    background: var(--primary-bg-200);
    cursor: pointer;
    border-radius: var(--border-radius);
}

#ngx-colors-overlay .opened .circle .circle .selected {
    border-radius: var(--border-radius);
}

#ngx-colors-overlay .opened .manual-input-wrapper {
    display: none !important;
}

#ngx-colors-overlay .opened .ng-trigger {
    -webkit-transition: none !important;
    transition: none !important;
}

#ngx-colors-overlay .opened .ng-trigger-colorsAnimation {
    -webkit-transition: none !important;
    transition: none !important;
}
