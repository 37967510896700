@import './bs-progress-bar.scss';
@import './dark-scheme.scss';
@import './light-scheme.scss';
@import './line-clamps.scss';
@import './buttons.scss';
@import './badges.scss';
@import './theme-colors.scss';
@import './dialogs.scss';
@import './color-picker.scss';
@import './suneditor.scss';
@import './meter-group.scss';
@import './primeng-rtl.scss';
// @import './kendo-ui/default-main-dark.scss';
body[dir='rtl'] {
  line-height: 1.5;
}
.layout-menu-wrapper .layout-menu-container .layout-menu > li:first-child {
  margin-top: 0;
}
.wrapped-input-component {
  display: contents;
}
.p-datatable .p-column-filter-menu-button {
  margin-top: 2px !important;
}
.p-datatable.p-datatable-gridlines {
  .p-paginator-bottom,
  .p-datatable-header {
    border: none;
    // padding-left: 0;
    // padding-right: 0;
  }
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-divider.p-divider-horizontal .p-divider-content {
  background: var(--primary-bg-400);
  border-radius: var(--border-radius);
  color: var(--primary-400);
}
.p-divider.p-divider-horizontal:before {
  border-top: 2px solid var(--primary-400);
}
.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: var(--primary-bg-400) !important;
}
.p-tabmenu .p-tabmenu-nav {
  width: 100%;
  min-width: fit-content;
}
.view-field-mode .field,
.field-m-0 .field {
  margin-bottom: 0 !important;
}
.card {
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
}
.layout-menu-wrapper .layout-menu-container .layout-menu li > a {
  border-radius: var(--border-radius) !important;
  -moz-border-radius: var(--border-radius) !important;
  -webkit-border-radius: var(--border-radius) !important;
}
.layout-menu-wrapper .layout-menu-container .layout-menu li {
  border-radius: var(--border-radius) !important;
  -moz-border-radius: var(--border-radius) !important;
  -webkit-border-radius: var(--border-radius) !important;
}
.layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a {
  border-radius: var(--border-radius) !important;
  -moz-border-radius: var(--border-radius) !important;
  -webkit-border-radius: var(--border-radius) !important;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}
.p-dialog {
  border-radius: var(--border-radius) !important;
  -moz-border-radius: var(--border-radius) !important;
  -webkit-border-radius: var(--border-radius) !important;
}
.p-dialog .p-dialog-header {
  border-top-right-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}
.p-picklist .p-picklist-list-wrapper {
  border-radius: var(--border-radius) !important;
  -moz-border-radius: var(--border-radius) !important;
  -webkit-border-radius: var(--border-radius) !important;
}
.p-overlay {
  max-width: 100%;
}
.p-tooltip {
  max-width: 60vw !important;
}
.p-treeselect-close.p-link {
  display: none;
}
.p-button.p-button-icon-only {
  padding: 0.429rem 0.571rem;
}
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up,
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  padding: 0;
}
.p-column-filter-overlay {
  min-width: 24rem;
}
.text-deep-gray-400 {
  color: var(--gray-400) !important;
}
.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}
.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}
.layout-breadcrumb-container .layout-breadcrumb ol li.p-menuitem-separator {
  margin: 0 0.75rem;
  color: #c8ccd8;
}
.p-breadcrumb ul li.p-menuitem-separator {
  color: #c8ccd8;
}
.p-treeselect-label-container .p-element.p-icon-wrapper {
  display: inherit !important;
}
.p-multiselect-label-container .p-element.p-icon-wrapper {
  display: inherit !important;
}
// .p-treeselect .p-overlay.p-component{
//     min-width: fit-content !important;
// }
.p-dialog-mask {
  overflow: auto;
}
.p-dialog .p-dialog-footer {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  width: 2.5rem !important;
  height: 2.5rem !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: unset !important;
}
.p-tree .p-tree-container .p-treenode {
  padding: unset !important;
}
.layout-footer {
  height: 40px;
  padding: 0 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.layout-breadcrumb-container {
  background-color: transparent;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout-wrapper .layout-main .layout-main-content {
  flex: 1 1 0;
  padding: 0px 30px;
}
.layout-wrapper {
  background: none !important;
}
.p-dialog .p-dialog-header {
  padding: 1.286rem 1.714rem 0 1.714rem !important;
}
.p-menu .p-menuitem-separator {
  border-top: 1px solid var(--surface-200);
  margin: 0.25rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  margin-right: 0 !important;
}
.p-autocomplete .p-autocomplete-multiple-container {
  gap: 0.5rem;
  width: 100% !important;
}
//   .p-dialog-mask.p-component-overlay.p-component-overlay-enter.p-dialog-mask-scrollblocker{
//     z-index: 9999999 !important;
//   }
.luckysheet-scrollbar-ltr {
  z-index: 998 !important;
}

.layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a > i {
  font-size: 1.5rem;
}
// .settings-button{
//     a{
//         display: none;
//     }
//     :hover{
//         a{
//             display: block;
//         }
//     }
// }
.hide-if-empty {
  &:empty {
    display: none !important;
  }
}
.text-deep-orange-600 {
  color: var(--orange-700) !important;
}
.bg-deep-orange-100 {
  background-color: var(--orange-200) !important;
}
.p-tree-toggler {
  align-self: flex-start;
}
.hide-selection {
  ::selection {
    // background-color: transparent; /* Yellow */
    // color: transparent; /* Dark Gray */
  }

  /* For Firefox */
  ::-moz-selection {
    // background-color: transparent;
    // color: transparent;
  }
}
.primary-bg-400 {
  background: var(--primary-bg-400);
}
//TABLE
// .p-datatable.p-datatable-lines .p-datatable-header {
//     border-width: 1px 1px 0 1px;
//   }
//   .p-datatable.p-datatable-lines .p-datatable-footer {
//     border-width: 0 1px 1px 1px;
//   }
//   .p-datatable.p-datatable-lines .p-paginator-top {
//     border-width: 0 1px 0 1px;
//   }
//   .p-datatable.p-datatable-lines .p-paginator-bottom {
//     border-width: 0 1px 1px 1px;
//   }
.p-datatable.p-datatable-lines .p-datatable-thead > tr > th {
  border-width: 0 0 1px 0;
}
.p-datatable.p-datatable-lines .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 0;
}
.p-datatable.p-datatable-lines .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 0;
}
.p-paginator-bottom {
  border-width: 0;
}
// .p-treetable.p-treetable-lines .p-datatable-header {
//     border-width: 1px 1px 0 1px;
//   }
//   .p-treetable.p-treetable-lines .p-treetable-footer {
//     border-width: 0 1px 1px 1px;
//   }
//   .p-treetable.p-treetable-lines .p-treetable-top {
//     border-width: 0 1px 0 1px;
//   }
//   .p-treetable.p-treetable-lines .p-treetable-bottom {
//     border-width: 0 1px 1px 1px;
//   }
.p-treetable.p-treetable-lines .p-treetable-thead > tr > th {
  border-width: 0 0 1px 0;
}
.p-treetable.p-treetable-lines .p-treetable-tbody > tr > td {
  border-width: 0 0 1px 0;
}
.p-treetable.p-treetable-lines .p-treetable-tfoot > tr > td {
  border-width: 0 0 1px 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: rgba(255, 255, 255, 0.05);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-disabled).p-focus {
  background: color-mix(in srgb, var(--primary-500) 70%, transparent);
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: rgba(255, 255, 255, 0.05);
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-disabled).p-focus {
  background: color-mix(in srgb, var(--primary-500) 70%, transparent);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: rgba(255, 255, 255, 0.05);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-disabled).p-focus {
  background: color-mix(in srgb, var(--primary-500) 70%, transparent);
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: rgba(255, 255, 255, 0.05);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-disabled).p-focus {
  background: color-mix(in srgb, var(--primary-500) 70%, transparent);
}
.license-message {
  .p-message.p-message-error {
    background: var(--red-100) !important;
    border: solid var(--red-600) !important;
    border-width: 0 0 0 6px !important;
    color: var(--red-600) !important;
    .p-message-icon {
      color: var(--red-600) !important;
    }
    .p-message-close {
      color: var(--red-600) !important;
    }
  }
  .p-message.p-message-warn {
    background: var(--yellow-100) !important;
    border: solid var(--yellow-600) !important;
    border-width: 0 0 0 6px !important;
    color: var(--yellow-600) !important;
    .p-message-icon {
      color: var(--yellow-600) !important;
    }
    .p-message-close {
      color: var(--yellow-600) !important;
    }
  }
}
.p-divider-content {
  overflow: hidden;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-inplace {
  input {
    border-end-end-radius: 0;
    border-start-end-radius: 0;
  }
  button {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}
.p-inplace.inplace-align-super .p-inplace-display {
  vertical-align: super;
}
.layout-topbar .layout-topbar-left .app-name {
  height: 2.714rem;
}
.p-breadcrumb-list {
  flex-wrap: wrap !important;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 1px solid !important;
  outline-color: var(--primary-600) !important;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 1px solid var(--primary-600) !important;
}
.layout-sidebar-active .second-level-menu-item {
  padding-left: 8px !important;
}
.h-22px {
  height: 22px;
}
.ngx-ic-cropper {
  opacity: 0.6;
  color: var(--surface-900) !important;
}
.transparency-bg {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;
}
.ngx-ic-square {
  background: var(--surface-900) !important;
}
:root {
  @each $key, $value in $deep-orange {
    --deep-orange-#{$key}: #{$value};
  }
  @each $key, $value in $deep-purple {
    --deep-purple-#{$key}: #{$value};
  }
  @each $key, $value in $blue-gray {
    --blue-gray-#{$key}: #{$value};
  }
  @each $key, $value in $gold {
    --gold-#{$key}: #{$value};
  }
  --cropper-overlay-color: transparent;
  --cropper-outline-color: var(--primary-bg-400);
}
.luckysheet-cell-input {
  color: var(--surface-50);
}
.pick-list-view-mode {
  .p-picklist-source-wrapper {
    display: none;
  }
  .p-picklist .p-picklist-buttons {
    display: none;
  }
  .p-picklist .p-picklist-header {
    display: none;
  }
}
.inner-absolute-input-value {
  left: 0;
  top: 1.5rem;
}
.p-dialog-content .flex.flex-row.md\:flex-nowrap.flex-wrap,
.p-sidebar .flex.flex-row.md\:flex-nowrap.flex-wrap {
  flex-wrap: wrap !important;
}
.p-dialog-content .md\:w-12rem.md\:max-w-12rem.md\:min-w-12rem.w-full {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  word-break: break-word;
}
.p-dialog-content .dynamic-field-item .flex.flex-row.md\:flex-nowrap.flex-wrap,
.p-sidebar .flex.flex-row.md\:flex-nowrap.flex-wrap {
  flex-wrap: nowrap !important;
}
.p-dialog-content .dynamic-field-item .md\:w-12rem.md\:max-w-12rem.md\:min-w-12rem.w-full {
  width: 12rem !important;
  max-width: 12rem !important;
  min-width: 12rem !important;
  word-break: break-word;
}
.input-field-label {
  font-weight: 600;
  color: var(--surface-500);
}
.p-buttonset .p-button:last-of-type.p-button:first-of-type {
  border-radius: var(--border-radius);
}
.custom-sun .sun-editor-editable {
  word-break: break-word;
}
.p-button:not(.p-button-outlined, .p-button-text, .p-button-link, .p-togglebutton) {
  box-shadow: none;
}
.layout-menuitem-icon {
  .p-badge {
    scale: 0.5;
    right: 4px;
    // top: -2px;
    line-height: 1.4rem;
  }
}
.nav-menu-search-input {
  margin-top: 0.5rem;
  margin-right: 26px;
  margin-bottom: 0px;
  margin-left: 22px;
  .search-icon {
    top: 50%;
    left: 0.9rem;
    transform: translate(0%, -50%);
    margin-top: 0;
  }
  .search-input {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 2.68rem;
    padding-right: 0.571rem;
    background: transparent;
  }
}
.layout-menuitem-icon-custom {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 0px;
  padding-bottom: 8px;
}
@media (min-width: 767px) {
  .inner-absolute-input-value {
    left: 12rem;
    top: 0;
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-sidebar .layout-menu-wrapper {
    width: 6.791em;
  }
  .p-dialog-content .inner-absolute-input-value {
    left: 0;
    right: 0;
    top: 1.5rem;
  }
}

.p-inputgroup .p-inputtext,
.p-fluid .p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper,
.p-inputgroup .p-inputwrapper > .p-component {
  flex: 1 1 auto;
  width: 100%;
}
.hide-children-hover {
  .hidden-item {
    display: none;
  }
}
.hide-children-hover:hover {
  .hidden-item {
    display: initial;
  }
}

// @media (min-width: 992px) {
//     .layout-wrapper.layout-sidebar .layout-menu-wrapper {
//         width: 6.791em;
//     }
//     .inner-absolute-input-value {
//         left: 12rem;
//         top: 0;
//     }
//     .p-dialog-content .inner-absolute-input-value {
//         left: 0;
//         top: 1.5rem;
//     }
// }
