.p-dialog {
    .p-dialog-header {
        background: var(--primary-bg-400);
    }
    .p-dialog-content {
        background: var(--primary-bg-400);
    }
    .p-dialog-footer {
        background: var(--primary-bg-400);
    }
}

.dialog-warn {
    .p-dialog-header {
        background: var(--yellow-100);
        color: var(--yellow-600);
        i {
            color: var(--yellow-700);
        }
        .p-dialog-header-close {
            color: var(--yellow-700) !important;
        }
    }
    .p-dialog-content {
        background: var(--yellow-100);
        color: var(--yellow-600);
        i {
            color: var(--yellow-700);
        }
    }
    .p-dialog-footer {
        background: var(--yellow-100);
        color: var(--yellow-600);
        i {
            color: var(--yellow-700);
        }
    }
}
.dialog-error {
    .p-dialog-header {
        background: var(--red-100);
        color: var(--red-600);
        i {
            color: var(--red-700);
        }
        .p-dialog-header-close {
            color: var(--red-700) !important;
        }
    }
    .p-dialog-content {
        background: var(--red-100);
        color: var(--red-600);
        i {
            color: var(--red-700);
        }
    }
    .p-dialog-footer {
        background: var(--red-100);
        color: var(--red-600);
        i {
            color: var(--red-700);
        }
    }
}
.p-sidebar.p-sidebar-right {
    border-radius: 0;
}
