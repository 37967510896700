$colors-custom: "red" #E53935 rgba(229, 57, 53, 0.15),
    "pink" #D81B60 rgba(216, 27, 96, 0.15),
    "purple" #8E24AA rgba(142, 36, 170, 0.15),
    "deep-purple" #5E35B1 rgba(94, 53, 177, 0.15),
    "indigo" #3949AB rgba(57, 73, 171, 0.15),
    "blue" #1E88E5 rgba(30, 136, 229, 0.15),
    "light-blue" #039BE5 rgba(3, 155, 229, 0.15),
    "cyan" #00ACC1 rgba(0, 172, 193, 0.15),
    "teal" #00897B rgba(0, 137, 123, 0.15),
    "green" #43A047 rgba(67, 160, 71, 0.15),
    "light-green" #7CB342 rgba(124, 179, 66, 0.15),
    "lime" #C0CA33 rgba(192, 202, 51, 0.15),
    "yellow" #FDD835 rgba(253, 216, 53, 0.15),
    "amber" #FFB300 rgba(255, 179, 0, 0.15),
    "orange" #FB8C00 rgba(251, 140, 0, 0.15),
    "deep-orange" #F4511E rgba(244, 81, 30, 0.15),
    "brown" #6D4C41 rgba(109, 76, 65, 0.15),
    "gray" #757575 rgb(117, 117, 117, 0.15),
    "blue-gray" #546E7A rgba(84, 110, 122, 0.15),
    "deep-gray" #828282 rgba(16, 16, 16, 0.35),
    "black" #000000 rgba(0, 0, 0, 0.15),
    "white" #ffffff rgba(0, 0, 0, 0.15);

$colors-custom-button:
    "red" #E53935 rgba(229, 57, 53, 0.7),
    "pink" #D81B60 rgba(216, 27, 96, 0.7),
    "purple" #8E24AA rgba(142, 36, 170, 0.7),
    "deep-purple" #5E35B1 rgba(94, 53, 177, 0.7),
    "indigo" #3949AB rgba(57, 73, 171, 0.7),
    "blue" #1E88E5 rgba(30, 136, 229, 0.7),
    "light-blue" #039BE5 rgba(3, 155, 229, 0.7),
    "cyan" #00ACC1 rgba(0, 172, 193, 0.7),
    "teal" #00897B rgba(0, 137, 123, 0.7),
    "green" #43A047 rgba(67, 160, 71, 0.7),
    "light-green" #7CB342 rgba(124, 179, 66, 0.7),
    "lime" #C0CA33 rgba(192, 202, 51, 0.7),
    "yellow" #FDD835 rgba(253, 216, 53, 0.7),
    "amber" #FFB300 rgba(255, 179, 0, 0.7),
    "orange" #FB8C00 rgba(251, 140, 0, 0.7),
    "deep-orange" #F4511E rgba(244, 81, 30, 0.7),
    "brown" #6D4C41 rgba(109, 76, 65, 0.7),
    "gray" #757575 rgb(117, 117, 117, 0.7),
    "blue-gray" #546E7A rgba(84, 110, 122, 0.7),
    "deep-gray" #828282 rgba(16, 16, 16, 0.7),
    "black" #000000 rgba(0, 0, 0, 0.7),
    "white" #ffffff rgba(0, 0, 0, 0.7);

$colors-solid: "red" #f11541 rgba(241, 21, 65, 0.15),
    "pink" #fd0dfd rgba(253, 13, 253, 0.15),
    "purple" #6f42c1 rgba(111, 66, 193, 0.15),
    "indigo" #3f51b5 rgba(63, 81, 181, 0.15),
    "blue" #0d6efd rgba(13, 110, 253, 0.15),
    "cyan" #0dcaf0 rgba(13, 202, 240, 0.15),
    "teal" #009688 rgba(0, 150, 136, 0.15),
    "green" #198754 rgba(25, 135, 84, 0.15),
    "yellow" #f0ad4e rgba(240, 173, 78, 0.15),
    "orange" #fd7e14 rgba(253, 126, 20, 0.15),
    "deep-orange" #ff5722 rgba(255, 87, 34, 0.15),
    "brown" #964b00 rgba(150, 75, 0, 0.15),
    "gray" #9e9e9e rgba(158, 158, 158, 0.15),
    "deep-gray" #828282 rgba(16, 16, 16, 0.35),
    "black" #000000 rgba(0, 0, 0, 0.15), "white" #ffffff rgba(0, 0, 0, 0.15);

$colors: (
    "red": #f44336,
    "pink": #e91e63,
    "purple": #9c27b0,
    "indigo": #3f51b5,
    "blue": #2196f3,
    "cyan": #00bcd4,
    "teal": #009688,
    "green": #4caf50,
    "yellow": #ffe821,
    "orange": #ff9800,
    "deep-orange": #ff5722,
    "brown": #795548,
    "gray": #9e9e9e,
    "deep-gray": #9e9e9e,
    "black": #000000,
    "white": #ffffff,
) !default;
$deep-orange: (
  50: #FBE9E7, 100: #FFCCBC, 200: #FFAB91,
  300: #FF8A65, 400: #FF7043, 500: #FF5722,
  600: #F4511E, 700: #E64A19, 800: #D84315, 900: #BF360C,
);
$deep-purple: (
  50: #EDE7F6, 100: #D1C4E9, 200: #B39DDB,
  300: #9575CD, 400: #7E57C2, 500: #673AB7,
  600: #5E35B1, 700: #512DA8, 800: #4527A0, 900: #311B92,
);
$blue-gray: (
  50: #ECEFF1, 100: #CFD8DC, 200: #B0BEC5,
  300: #90A4AE, 400: #78909C, 500: #607D8B,
  600: #546E7A, 700: #455A64, 800: #37474F, 900: #263238,
);
$gold: (
  50: #FAFAD2,
  100: #EEE8AA,
  200: #FFDF00,
  300: #CFB53B,
  400: #FFD700,
  500: #C5B358,
  600: #D4AF37,
  700: #DAA520,
  800: #D8A31B,
  900: #B8860B,
);