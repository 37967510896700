$colors-custom:
  'red' #e53935 rgba(229, 57, 53, 0.15),
  'pink' #d81b60 rgba(216, 27, 96, 0.15),
  'purple' #8e24aa rgba(142, 36, 170, 0.15),
  'deep-purple' #5e35b1 rgba(94, 53, 177, 0.15),
  'indigo' #3949ab rgba(57, 73, 171, 0.15),
  'blue' #1e88e5 rgba(30, 136, 229, 0.15),
  'light-blue' #039be5 rgba(3, 155, 229, 0.15),
  'cyan' #00acc1 rgba(0, 172, 193, 0.15),
  'teal' #00897b rgba(0, 137, 123, 0.15),
  'green' #43a047 rgba(67, 160, 71, 0.15),
  'light-green' #7cb342 rgba(124, 179, 66, 0.15),
  'lime' #c0ca33 rgba(192, 202, 51, 0.15),
  'yellow' #fdd835 rgba(253, 216, 53, 0.15),
  'amber' #ffb300 rgba(255, 179, 0, 0.15),
  'orange' #fb8c00 rgba(251, 140, 0, 0.15),
  'deep-orange' #f4511e rgba(244, 81, 30, 0.15),
  'brown' #6d4c41 rgba(109, 76, 65, 0.15),
  'gray' #757575 rgb(117, 117, 117, 0.15),
  'blue-gray' #546e7a rgba(84, 110, 122, 0.15),
  'deep-gray' #828282 rgba(16, 16, 16, 0.35),
  'black' #000000 rgba(0, 0, 0, 0.15),
  'white' #ffffff rgba(0, 0, 0, 0.15);

@each $key, $val, $bgval in $colors-custom {
  .badge-custom-#{$key} {
    color: $val !important;
    background-color: $bgval;
    border: none;
    padding: 5px 12px;
    font-weight: 500;
    line-height: 1.2;
  }
}
@each $key, $val in $deep-orange {
  .bg-deep-orange-#{$key} {
    background-color: $val;
  }
}
@each $key, $val in $deep-purple {
  .bg-deep-purple-#{$key} {
    background-color: $val;
  }
}
@each $key, $val in $blue-gray {
  .bg-deep-gray-#{$key} {
    background-color: $val;
  }
  .bg-blue-gray-#{$key} {
    background-color: $val;
  }
}
.badge-custom-gold {
  color: #ffd700 !important; /* Golden yellow color */
  background-color: rgba(255, 215, 0, 0.15); /* Light golden yellow background aksdjah kdjashdkjahdkd jhaksdja hsdkj*/
  border: none;
  padding: 5px 12px;
  font-weight: 500;
  line-height: 1.2;

  /* Shining effect animation */
  position: relative;
  overflow: hidden;
}
::ng-deep .p-highlight .badge {
  background-color: var(--surface-100) !important;
}
.p-highlight .badge {
  background-color: var(--surface-100) !important;
}
@each $key, $val, $bgval in $colors-solid {
  .badge-solid-#{$key} {
    color: $val !important;
    background-color: $bgval;
    border: none;
    padding: 5px 12px;
    font-weight: 500;
    line-height: 1.2;
  }
}
.badge-circle {
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 5px 12px;
  font-weight: 500;
  line-height: 1.2;
}

@each $key, $val in $colors {
  .badge-outline-#{$key} {
    color: $val !important;
    border: 1px solid;
  }
}
.badge {
  border-radius: var(--border-radius);
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
  max-width: fit-content;
}
.small-badge {
  border-radius: var(--border-radius);
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 0.3px;
  line-height: 14px;
  max-width: fit-content;
}

.badge-custom-gold::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.318), transparent);
  animation: shining 1.5s infinite;
}
.badge-shining {
  /* Shining effect animation */
  position: relative;
  overflow: hidden;
}
.badge-shining::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.318), transparent);
  animation: shining 1.8s infinite;
}

@keyframes shining {
  to {
    left: 100%;
  }
}

.badge-pulse {
  /* Flickering effect animation */
  position: relative;
  overflow: hidden;
}
.badge-pulse::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.318);
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
