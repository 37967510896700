/* You can add global styles to this file, and also import other style files */
@import 'assets/sass/custom/breakpoints.scss';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
// @import '../node_modules/suneditor/dist/css/suneditor.min';
// @import "../node_modules/@fullcalendar/daygrid/main.min.css";
// @import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import 'assets/demo/flags/flags.css';
@import 'assets/demo/documentation.scss';
@import 'assets/sass/custom/index.scss';
.toast-z-index {
  z-index: 99999;
  word-break: break-word;
}
.p-toast .p-toast-message .p-toast-icon-close,
.p-message .p-message-close {
  min-width: 1rem !important;
}
::ng-deep .field-m-0 {
  .field,
  .field-checkbox,
  .field-radiobutton {
    margin: 0 !important;
  }
}
.p-dialog .p-dialog-content {
  padding: 1.25rem 1.714rem 1.714rem 1.714rem;
}

.layout-wrapper.layout-sidebar .layout-menu-wrapper {
  border-radius: 0 !important;
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
  display: none;
}
div::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
  display: none;
}
body::-webkit-scrollbar,
div::-webkit-scrollbar,
gridster::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
gridster::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
gridster::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background-color: var(--surface-400);
  outline: 1px solid var(--surface-200);
  border-radius: 3px;
}
html > body {
  // scrollbar-color: var(--surface-200) var(--surface-400) ;
  width: calc(
    100vw - 0.6em
  ); //subtract width of the scrollbar to make body stable on all cases (hidden overflow or shown)
}
// gridster-item {
//     // background: var(--surface-50) !important;
// }
gridster {
  background: transparent !important;
  // scrollbar-color: var(--surface-200) var(--surface-400) ;
}

.highlight {
  background-color: yellow;
  color: black;
}

.p-tabview-panels {
  padding-bottom: 0 !important;
}
[dir='rtl'] .cdk-drag-preview {
  direction: rtl !important; /* Prevents mirroring issues */
  text-align: start !important;
}
