//styles written in the ltr mode and the postcss will handle the fixes
.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-top-left-radius: 0;
  border-top-right-radius: var(--border-radius);
}
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--border-radius);
}
.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
/*rtl:ignore*/
[dir='rtl'] .p-datepicker .p-datepicker-header .p-datepicker-prev,
[dir='rtl'] :host .p-datepicker .p-datepicker-header .p-datepicker-prev,
[dir='rtl'] .p-datepicker .p-datepicker-header .p-datepicker-next,
[dir='rtl'] :host .p-datepicker .p-datepicker-header .p-datepicker-next {
  transform: rotate(180deg);
}
/*rtl:ignore*/
[dir='rtl'] {
  .p-picklist .p-picklist-buttons .p-button {
    transform: rotate(180deg);
  }
  :host {
    .p-picklist .p-picklist-buttons .p-button {
      transform: rotate(180deg);
    }
  }
}
/*rtl:ignore*/
[dir='rtl'] {
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    transform: rotate(180deg);
  }
  :host {
    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
      transform: rotate(180deg);
    }
  }
}
/*rtl:ignore*/
[dir='rtl'] {
  .p-breadcrumb .p-menuitem-separator {
    transform: rotate(180deg);
  }
  :host {
    .p-breadcrumb .p-menuitem-separator {
      transform: rotate(180deg);
    }
  }
}
