.light-color-scheme {
  .bg-input-field {
    background: rgba(68, 72, 109, 0.07);
  }
  gridster-item {
    background: transparent !important;
  }
  .p-dialog {
    border: solid 1px var(--surface-400);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    background: var(--surface-50) !important;
  }
  .p-datatable .p-datatable-header {
    border-top: 0;
  }
  .p-stepper .p-stepper-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: auto;
  }
  .p-stepper-vertical .p-stepper-nav {
    flex-direction: column;
  }
  .p-stepper-header {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
  .p-stepper-header:last-of-type {
    flex: initial;
  }
  .p-stepper-header .p-stepper-action {
    border: 0 none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  .p-stepper-header .p-stepper-action:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-stepper.p-stepper-readonly .p-stepper-header {
    cursor: auto;
  }
  .p-stepper-header.p-highlight .p-stepper-action {
    cursor: default;
  }
  .p-stepper-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .p-stepper-number {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-stepper-separator {
    flex: 1 1 0;
  }
  .p-stepper .p-stepper-nav {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .p-stepper .p-stepper-header {
    padding: 0.5rem;
  }
  .p-stepper .p-stepper-header .p-stepper-action {
    // transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 6px;
    // background: rgba(255, 255, 255, 0.05);
    outline-color: transparent;
  }
  .p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
    // color: #64748b;
    border: 1px solid #e2e8f0;
    border-width: 2px;
    // background: rgba(255, 255, 255, 0.05);
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    border-radius: 50%;
    // transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
    margin-left: 0.5rem;
    // color: #334155;
    font-weight: 500;
    // transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    // background: rgba(255, 255, 255, 0.05);
    // color: #1d4ed8;
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
    // color: #334155;
  }
  .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }
  .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
    // background: rgba(255, 255, 255, 0.05);
  }
  .p-stepper .p-stepper-panels {
    // background: #ffffff;
    padding: 0.875rem 1.125rem 1.125rem 1.125rem;
    // color: #334155;
  }
  .p-stepper .p-stepper-separator {
    // background-color: rgba(255, 255, 255, 0.05);
    width: 100%;
    height: 2px;
    margin-inline-start: 1rem;
    // transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-stepper.p-stepper-vertical {
    display: flex;
    flex-direction: column;
  }
  .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
    display: flex;
    flex: 1 1 auto;
    // background: rgba(255, 255, 255, 0.05);
    // color: #334155;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel {
    display: flex;
    flex-direction: column;
    flex: initial;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel.p-stepper-panel-active {
    flex: 1 1 auto;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
    flex: initial;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
    width: 100%;
    padding-left: 1rem;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
    flex: 0 0 auto;
    width: 2px;
    height: auto;
    margin-inline-start: calc(1.75rem + 2px);
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
    // background-color: #3b82f6;
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel:last-of-type .p-stepper-content {
    padding-left: 3rem;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    background: rgba(0, 0, 0, 0.05);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-disabled).p-focus {
    background: color-mix(in srgb, var(--primary-500) 70%, transparent);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    background: rgba(0, 0, 0, 0.05);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-disabled).p-focus {
    background: color-mix(in srgb, var(--primary-500) 70%, transparent);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    background: rgba(0, 0, 0, 0.05);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-disabled).p-focus {
    background: color-mix(in srgb, var(--primary-500) 70%, transparent);
  }
  .p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item:not(.p-highlight):not(.p-disabled).p-focus {
    background: rgba(0, 0, 0, 0.05);
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-disabled).p-focus {
    background: color-mix(in srgb, var(--primary-500) 70%, transparent);
  }
}
