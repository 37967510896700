@import "./theme-colors.scss";

$textButtonHoverBgOpacity:.04 !default;
$textButtonActiveBgOpacity:.16 !default;

@each $key, $val, $bgval in $colors-custom-button {
    .p-button.p-button-#{$key}, .p-buttonset.p-button-#{$key} > .p-button, .p-splitbutton.p-button-#{$key} > .p-button {
        color: white;
        background: $val;
        border: $bgval;

        &:enabled:hover {
            background: $bgval;
            color: white;
            border-color: $bgval;
        }

        &:enabled:focus {
            box-shadow: $bgval;
        }

        &:enabled:active {
            background: $bgval;
            color: white;
            border-color: $bgval;
        }

        &.p-button-outlined {
            background-color: transparent;
            color: $val;
            border: $val;

            &:enabled:hover {
                background: rgba($val, $textButtonHoverBgOpacity);
                color: $val;
                border: $val;
            }

            &:enabled:active {
                background: rgba($val, $textButtonActiveBgOpacity);
                color: $val;
                border: $val;
            }
        }

        &.p-button-text {
            background-color: transparent;
            color: $val;
            border-color: transparent;

            &:enabled:hover {
                background: rgba($val, $textButtonHoverBgOpacity);
                border-color: transparent;
                color: $val;
            }

            &:enabled:active {
                background: rgba($val, $textButtonActiveBgOpacity);
                border-color: transparent;
                color: $val;
            }
        }
    }
    .p-button.p-button-#{$key}:enabled:focus, a.p-button.p-button-#{$key}:focus, .p-buttonset.p-button-#{$key} > .p-button:enabled:focus, .p-splitbutton.p-button-#{$key} > .p-button:enabled:focus  {
        box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px $bgval, 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
    }
    .p-button.p-button-#{$key}, .p-buttonset.p-button-#{$key} > .p-button, .p-splitbutton.p-button-#{$key} > .p-button{
        box-shadow: 0px 0px 10px rgba($val,0.15);
    }
}
.p-button:enabled:focus {
    box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px var(--primary-300), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
.p-button:focus {
    box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px var(--primary-300), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
.p-button.p-button-secondary:enabled:focus, a.p-button.p-button-secondary:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus  {
  box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px rgba(160, 163, 173, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
.p-button.p-button-success:enabled:focus, a.p-button.p-button-success:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px rgba(67, 160, 71, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
.p-button.p-button-info:enabled:focus, a.p-button.p-button-info:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus  {
  box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px rgba(57, 73, 171, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
.p-button.p-button-warning:enabled:focus, a.p-button.p-button-warning:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus  {
  box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px rgba(238, 229, 0, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
.p-button.p-button-help:enabled:focus, a.p-button.p-button-help:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus  {
  box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px rgba(216, 27, 96,0.7), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
.p-button.p-button-danger:enabled:focus, a.p-button.p-button-danger:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus  {
  box-shadow: 0 0 0 2px var(--surface-100), 0 0 0 4px rgba(229, 57, 53, 0.7), 0 1px 2px 0 rgba(0, 0, 0, 0) !important;
}
