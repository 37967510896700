$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1300px;
$xxl: 1700px;
$breakpoints: (
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl,
  'xxl': $xxl,
);
.max-w-calc-full {
  max-width: calc(100%);
}
@for $i from 1 through 30 {
  .min-w-#{$i}rem {
    min-width: #{$i}rem;
  }
  .min-h-#{$i}rem {
    min-height: #{$i}rem;
  }
  .max-w-calc-full-#{$i}rem {
    max-width: calc(100% - #{$i}rem);
  }
}
@each $breakpoint, $value in $breakpoints {
  @media screen and (min-width: #{$value}) {
    @for $i from 1 through 30 {
      .#{$breakpoint}\:min-w-#{$i}rem {
        min-width: #{$i}rem;
      }
      .#{$breakpoint}\:min-h-#{$i}rem {
        min-height: #{$i}rem;
      }
      .#{$breakpoint}\:max-w-calc-full {
        max-width: calc(100%); 
      }
      .#{$breakpoint}\:max-w-calc-full-#{$i}rem {
        max-width: calc(100% - #{$i}rem);
      }
    }
  }
}
